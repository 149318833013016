.image {
  + :global(.ant-image-mask) {
    :global(.ant-image-mask-info) {
      color: inherit;
    }
  }
}

.imageWrapper {
  :global(.ant-image-preview-progress) {
    color: inherit;
  }

  :global(.ant-image-preview-operations) {
    color: inherit;

    :global(.ant-image-preview-operations-operation) {
      color: inherit;
    }
  }
}
