@import "../../variables.module.scss";

.wrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div {
    flex-basis: 50%;
  }

  .header {
    font-size: 32px;
    font-weight: 500;
    color: $colorSecondaryBlack;
    margin-bottom: 48px;
    text-align: center;
  }

  .picture {
    background-image: url("/assets/images/logoBg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 20px;

    > svg {
      position: absolute;
      right: 24px;
      top: 24px;
    }

    .form {
      max-width: 430px;
      min-width: 430px;

      label:nth-child(3) {
        margin-bottom: 48px;

        &:global(.ant-checkbox-wrapper) {
          span {
            font-size: 0.813rem;
            // text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }

      .antFormButton {
        width: 100%;
        height: 56px;

        span {
          font-size: 0.938rem;
          font-weight: 500;
        }
      }
    }
  }
}
